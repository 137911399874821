import React from "react"
import { Link, graphql } from "gatsby"
import {
  Segment,
  Container,
  Icon,
  Header,
  Button,
  Grid,
  Statistic,
  StatisticGroup,
  StatisticValue,
  StatisticLabel,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmbedYoutube from "../components/EmbedYoutube"

const IndexPage = (args) => {
  const concepts = args.data.concepts.nodes;
  const pls = args.data.pls.nodes;
  const misconceptions = args.data.misconceptions.nodes;
  return (
    <Layout>
      <SEO/>

      <Segment
        inverted
        textAlign="center"
        style={{ padding: "1em 0em" }}
        vertical
      >
        <Container>
          {/*
          <div style={{ maxWidth: `150px`, marginBottom: `1.45rem` }}>
            <Image />
          </div>
          */}
          <Header
            as="h1"
            content="ProgMiscon"
            inverted
            style={{
              fontSize: "4em",
              fontWeight: "bold",
              marginTop: "1em",
              marginBottom: "0em",
            }}
          />
          <Header
            inverted
            as="h2"
            content="A Curated Inventory of Programming Language Misconceptions"
            style={{
              fontWeight: "normal",
              marginBottom: "2em",
            }}
          />
        </Container>
      </Segment>

      <Segment
        textAlign="center"
        style={{ padding: "1em 0em" }}
        vertical
      >
        <Container style={{ padding: "5em"}}>
          <StatisticGroup
            size="three"
            style={{
              marginTop: "1.5em",
              marginBottom: "1.5em",
            }}
          >
            <Statistic size="huge">
              <StatisticValue>{misconceptions.length}</StatisticValue>
              <StatisticLabel>Misconceptions</StatisticLabel>
            </Statistic>
            <Statistic size="huge">
              <StatisticValue>{concepts.length}</StatisticValue>
              <StatisticLabel>Concepts</StatisticLabel>
            </Statistic>
            <Statistic size="huge">
              <StatisticValue>{pls.length}</StatisticValue>
              <StatisticLabel>Languages</StatisticLabel>
            </Statistic>
          </StatisticGroup>
          <p>
            <b>Programming language misconception</b>: A statement that can be disproved by reasoning entirely based on the syntax and/or semantics of a programming language.
          </p>
        </Container>
      </Segment>

      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 500, padding: "1em 0em" }}
        vertical
      >
        <Container style={{ padding: "5em"}}>
          <Header
            inverted
            as="h2"
            content="Explore Misconceptions By Programming Language..."
            style={{
              fontWeight: "normal",
              marginBottom: "1em",
            }}
          />
          <div>
            <Button color="red" size="huge" as={Link} to="/misconceptions/Python/">
              Python
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/misconceptions/Java/">
              Java
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/misconceptions/JavaScript/">
              JavaScript
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/misconceptions/Scratch/">
              Scratch
            </Button>
          </div>
        </Container>

        <Container style={{ padding: "5em"}}>
          <Header
            inverted
            as="h2"
            content="Explore Misconceptions By..."
            style={{
              fontWeight: "normal",
              marginBottom: "1em",
            }}
          />
          <div>
            <Button color="red" size="huge" as={Link} to="/concepts/">
              <Icon name="lightbulb" />
              Concept
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/specs/">
              <Icon name="code" />
              Language Feature
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/notionalMachines/">
              <Icon name="settings" />
              Notional Machine
            </Button>
            <span style={{padding: '8px'}} />
            <Button color="red" size="huge" as={Link} to="/textbooks/">
              <Icon name="book" />
              Textbook
            </Button>
          </div>
        </Container>

        <Container style={{ padding: "5em"}}>
          <Header
            as="h2"
            content="Read the ProgMiscon Research Paper..."
            inverted
            style={{
              fontWeight: "normal",
              marginBottom: "1em",
            }}
          />
          <Container textAlign="left" style={{ marginBottom: "1em" }}>
            Luca Chiodini, Igor Moreno Santos, Andrea Gallidabino, Anya Tafliovich, André L. Santos, Matthias Hauswirth. {' '}
            <strong>A Curated Inventory of Programming Language Misconceptions</strong>. {' '}
            Proceedings of the 2021 ACM Conference on Innovation and Technology in Computer Science Education (ITiCSE '21), June 26-July 1, 2021, Virtual Event, Germany. {' '}
            <a href="https://doi.org/10.1145/3430665.3456343">10.1145/3430665.3456343</a>
          </Container>
          <EmbedYoutube
            id={"92Mfh3GI98Y"}
          />
        </Container>
      </Segment>

      <Segment style={{ padding: "6em 0em" }} vertical>
      <Container textAlign="center" style={{ padding: "5em" }}>
        <Header
          as="h2"
          content="Turn Misconceptions into Learning Opportunities"
          style={{
            fontSize: "2em",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        />
      </Container>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <Icon className="circular massive inverted red hand holding heart"/>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Misconceptions are Valuable!
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                The "misconceptions" documented in this inventory
                often are conceptions students constructed in thoughtful and ingenious ways.
                A misconception in one programming language
                may well be an existing or possibly future 
                language feature in another language.
                We believe misconceptions are valuable,
                and we point out their value so you can build on them.
              </p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <Icon className="circular inverted massive yellow school"/>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Rooted in Teaching Practice
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                This is a curated inventory of misconceptions students have while
                learning to program. This collection is based on observations in
                programming classrooms over the course of more than a decade.
              </p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <Icon className="circular inverted massive olive chalkboard teacher"/>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Resource For Teachers
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                If you teach programming, this site is for you. You can easily
                find and navigate the misconceptions your students might have,
                discover possible causes, and learn about possible teaching
                interventions.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                Navigate <Link to="/concepts/">by concept</Link>, or
                if your course uses a textbook we cross-referenced,
                find which <Link to="/textbooks/">misconceptions 
                your students may exhibit in the various chapters of your book</Link>.
              </p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <Icon className="circular inverted massive green seedling"/>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Growing Inventory
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                The site currently contains only a small fraction of the
                misconceptions we collected. We will continuously add more. We
                have a lot of material, and we are gathering more in our research
                into Conceptual Change when Learning to Program.
              </p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <Icon className="circular inverted massive blue code"/>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Programming Languages
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                We initially focused on Java,
                then added Python,
                included JavaScript,
                and most recently expanded the collection to Scratch.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                To ensure that our collection rests on a solid foundation,
                we explicitly connect each misconception 
                to the relevant sections of the authoritative language specifications.
                This also allows you 
                to <Link to="/specs/">look up misconceptions for specific language features</Link>.
              </p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button size="huge" as={Link} to="/conceptMatrix" style={{ marginTop: "3em" }}>
                <Icon name="th"></Icon>
                Misconception-Concept Matrix
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    concepts: allFile(
      filter: {absolutePath: {glob: "**/src/data/concepts/*.md"}},
      sort: {fields: name}
    ) {
      nodes {
        name
      }
    }
    pls: allFile(
      filter: {absolutePath: {glob: "**/src/data/pls/*.md"}},
      sort: {fields: name}
    ) {
      nodes {
        name
      }
    }
    misconceptionConcepts: allMdx {
      group(field: frontmatter___concepts) {
        fieldValue
        totalCount
      }
    }
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          concepts
          shortDescription
        }
      }
    }
  }
`
