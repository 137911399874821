import React from "react"
import PropTypes from "prop-types"
import { Embed } from "semantic-ui-react"

function EmbedYoutube({ id }) {
  return (
    <Embed
      autoplay={false}
      brandedUI
      color='#ffc001'
      defaultActive={true}
      hd={true}
      id={id}
      iframe={{
        allowFullScreen: true
      }}
      source='youtube'
    />
  )
}

EmbedYoutube.propTypes = {
  id: PropTypes.string.isRequired,
}

export default EmbedYoutube;
